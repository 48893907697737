import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colImg1 from "../assets/img/colorization1.png"
import colImg2 from "../assets/img/colorization2.png"
import colImg3 from "../assets/img/colorization3.png"
import under1 from "../assets/img/underwater1.png"
import under2 from "../assets/img/underwater2.png"
import under3 from "../assets/img/underwater3.png"
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Spatial-Temporal Video Colorization ",
      description: "with Histogram-guided Structure",
      imgUrl: colImg1,
    },
    {
      title: "Context-aware Codebook and Multi-stage Model",
      description: "Vivid and Diverse Image Colorization",
      imgUrl: colImg3,
    },
    {
      title: "CVPR 2023 Video Colorization Challenge ",
      description: "CDC赛道 第1赛季 冠军",
      imgUrl: colImg2,
    },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg1,
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg2,
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg3,
    // },
  ];
  const underwaterProjects = [
    {
      title: "Generalized Physical knowledge-guided Dynamic Model",
      description: "Underwater Image Enhancement",
      imgUrl: under1,
    },
    {
      title: "Multi-level Feature Fusion Network",
      description: "Underwater Image Enhancement",
      imgUrl: under2,
    },
    {
      title: "Degradation-aware Dynamic Network",
      description: "Real-world Underwater Image  Enhancement",
      imgUrl: under3,
    },
  ];
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>本科阶段的科研成果设计图像以及的视频的着色，水下图像的增强任务等</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Colorization</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Underwater Enhancement</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Keep Going On..</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" >
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          underwaterProjects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p className="keepGoingOn">
                        <h4 className=".proj-txtx">致疯狂的人</h4>
                        他们特立独行，他们桀骜不驯
                        <br></br>
                        他们惹是生非，他们格格不入
                        <br></br>
                        他们用与众不同的眼光看待事物
                        <br></br>
                        他们不喜欢墨守成规，他们也不愿安于现状
                        <br></br>
                        你可以颂扬或诋毁他们，也可以反对他们
                        <br></br>
                        但唯独不能漠视他们
                        <br></br>
                        因为他们改变了寻常事物
                        <br></br>
                        他们推动了人类向前迈进
                        <br></br>
                        或许他们是别人眼里的疯子
                        <br></br>
                        但他们却是我们眼中的天才
                        <br></br>
                        因为那些疯狂到自以为可以改变世界的人
                        <br></br>
                        才能真正改变世界</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
